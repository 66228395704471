@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  > div {
    gap: 8px;
  }

  &-primary {
    padding: 8px 10px !important;

    @include min-428-break {
      padding: 13px 10px !important;
    }

    p[id='drop-list-sort-title'] {
      @include typography-s;
      @include weight-medium;

      @include min-428-break {
        @include typography-base;
      }
    }
  }

  &-secondary {
    padding: 11px 10px !important;

    @include min-428-break {
      padding: 15px 12px !important;
    }

    p[id='drop-list-sort-title'] {
      @include typography-s;
      @include weight-medium;

      @include min-428-break {
        @include typography-m;
      }
    }
  }

  &-on {
    background: var(--cst-drop-listsort-background-hover);
    box-shadow: inset 0 0 0 2px var(--cst-drop-listsort-outline-hover);

    p[id='drop-list-sort-title'] {
      color: var(--cst-drop-listsort-text-hover);
    }
  }

  span[id='drop-list-sort-arrow-icon'] {
    @include singleFixedSize(22px);
  }
}
