@use '@tabs' as *;
@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@button' as *;
@use '@queries' as *;
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10px 14px 10px 14px;
  box-shadow: var(--elevation-1);
  background-color: white;
  transition: transform 0.3s ease;
  position: absolute;
  z-index: 300;

  &-scroll-down {
    transform: translateY(-120px);
    @include min-428-break {
      transform: translateY(-135px);
    }
  }
  .content {
    display: flex;
    width: 100%;
    max-width: 1023px;
    flex-direction: column;
    gap: 8px;
    position: relative;
  }
  @include min-428-break {
    padding: 14px;
  }
  @include min-744-break {
    padding: 14px 28px;
  }
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &-right_slot {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;
    @include min-428-break {
      gap: 8px;
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: gap 0.2s ease-in-out;

  &-closed {
    gap: 30px;
  }
}

.balance-container {
  height: 42px;
  width: 83px;
  border: 2px solid rgba(246, 246, 246, 1);
  border-radius: var(--cornerradius10);

  @include min-428-break {
    height: 46px;
    width: 93px;
  }
}

.header-alternative {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom-left-radius: var(--cornerradius12);
  border-bottom-right-radius: var(--cornerradius12);
  -webkit-border-bottom-left-radius: var(--cornerradius12);
  -webkit-border-bottom-right-radius: var(--cornerradius12);
  box-shadow: var(--elevation-1);

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1023px;
  }
  .top {
    .input-container {
      box-shadow: none;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;

    padding: 0px 14px 12px 14px;

    @include min-428-break {
      padding: 0 14px 14px 14px;
      gap: 12px;
    }

    @include min-744-break {
      padding: 0 28px 14px 28px;
    }

    .search-results,
    .value {
      color: var(--cst-content-description);

      @include typography-s;
      @include weight-medium;

      @include min-428-break {
        @include typography-base;
      }

      .value {
        color: var(--cst-content-description-secondary);
        @include weight-semi-bold;
      }
    }

    .search-results {
      padding-top: 12px;

      @include min-428-break {
        padding-top: 14px;
      }
    }

    .drop-list-wrapper {
      display: flex;
      gap: 8px;
    }
  }
}

.no-shadow {
  box-shadow: none;
}
